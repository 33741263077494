import Agora from 'agora-rtc-sdk-ng';
import loader from '@/config.loader';
import auth from '@/cognito/aws-auth'

export const AppId = loader.getConfigValue('VUE_AGORA_ID');

export const getRTCToken = async (pod, uid) => {
  const token = await auth.signCheck();
  const tokenURL = loader.getConfigValue('VUE_AGORA_API_URL');  
  return fetch(`${tokenURL}/token/rtc`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ pod, uid })
  })
    .then(res => res.json())
    .then(res => res.token);
};

const client = Agora.createClient({
  codec: 'h264',
  mode: 'rtc',
  role: 'host'
});

Agora.disableLogUpload();

export const defaultMicrophoneConfig = {
  AEC: true,
  ANS: true
};

export default client;
