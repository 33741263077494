import AgoraRTM from 'agora-rtm-sdk';

import loader from '@/config.loader';
import auth from '@/cognito/aws-auth'

import { AppId } from './agora';

export const client = AgoraRTM.createInstance(AppId);

export const getRTMToken = async (account) => {
  const token = await auth.signCheck();
  const tokenURL = loader.getConfigValue('VUE_AGORA_API_URL');
  console.log(tokenURL)
  return fetch(`${tokenURL}/token/rtm`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ account })
  })
    .then(res => res.json())
    .then(res => res.token);
};
